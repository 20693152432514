let $ = window.$ || window.jQuery || require('jquery');
window.$ = window.jQuery = $;

import CartWidget from 'theme/js/cart/cartwidget';
import CartIconWidget from 'theme/js/cart/carticonwidget';

import FeatureFlagProvider from 'shared/js/featureFlagRegistrar';
const processInclude = require('theme/js/util');

// Dependencies pre-inclusion.
require('slick-carousel');
require('lazysizes');
require('theme/js/components/spinner');
require('theme/js/thirdParty/bootstrap');

// Dependencies inclusion on document-ready.
const modules = () => {
    processInclude(require('theme/js/components/eventListeners'));
    processInclude(require('theme/js/components/header'));
    processInclude(require('theme/js/components/menu'));
    processInclude(require('theme/js/components/cookie'));
    processInclude(require('theme/js/components/consentTracking'));
    processInclude(require('theme/js/components/footer'));
    processInclude(require('theme/js/components/collapsibleItem'));
    processInclude(require('theme/js/components/clientSideValidation'));
    processInclude(require('theme/js/components/countrySelector'));
    processInclude(require('theme/js/components/toolTip'));

    processInclude(require('theme/js/cart/miniCart')); // SFRA/wishlist cartridges - base/components/miniCart
    processInclude(require('theme/js/components/dropdown'));
    processInclude(require('theme/js/components/tabs'));
    processInclude(require('theme/js/components/search'));
    processInclude(require('theme/js/components/fold'));
    processInclude(require('theme/js/components/widgets/blogcarousel'));
    processInclude(require('theme/js/components/widgets/herobanner'));
    processInclude(require('theme/js/components/messageBanner'));
    processInclude(require('theme/js/product/quickView'));
};

// Initialize the miniCart widget.
FeatureFlagProvider.callback((load) => {
    load();

    CartIconWidget.init();
    // Whenever CartWidget re-renders the CartIconWidget also re-renders
    CartWidget.registerCallback('cartwidget:pre-render', function () {
        CartIconWidget.render();
    });

    CartWidget.init('storefront').render();
});

// On document ready event trigger.
$(document).ready(() => {
    let executedFlag = false;
    const loadModules = () => {
        if (!executedFlag) {
            executedFlag = true;
            modules();
        }
    };
    FeatureFlagProvider.getCallbacks().forEach(callback => callback(loadModules));
    loadModules(); // Load modules if that was not triggered earlier.

    if ($('.footer').attr('data-symplify-enabled') === 'true') {
        setTimeout(() => {
            // Call Symplify endpoint to extent Symplify cookie's expiration date
            $.ajax({
                url: $('.footer').attr('data-symplify-url'),
                method: 'POST'
            });
        }, '500');
    }

    // Images lazy load
    const mo = new MutationObserver(() => {
        if ($('img.lazy-loading').length) {
            const img = document.querySelectorAll('img.lazy-loading');
            const appearOptions = {
                threshold: 0
            };

            const io = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (!entry.isIntersecting) return;
                    entry.target.src = entry.target.attributes['data-src'] ? entry.target.attributes['data-src'].value : entry.target.src;
                    entry.target.classList.remove('lazy-loading');
                    entry.target.classList.add('lazy-loaded');
                    io.unobserve(entry.target);
                });
            }, appearOptions);

            img.forEach(el => {
                io.observe(el);
            });
        }
    });

    mo.observe(document.querySelector('body'), {
        childList: true,
        subtree: true
    });
});
