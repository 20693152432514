'use strict';

var base = require('theme/js/product/base');
var focusHelper = require('theme/js/components/focus');
import _ from 'shared/js/underscore';

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

function showCartBox(data) {
    $('.item-' + data.product.UUID).empty().append(data.product.renderedPromotions);
    if (data.product.price.sales.formatted) {
        $('.item-total-' + data.product.UUID).empty().append(data.product.price.sales.formatted);
    } else {
        $('.item-total-' + data.product.UUID).empty().append(data.product.price.list.formatted);
    }
    $('.cart-box-item-title').empty().append(data.product.productName);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

function hideCartItemsHeader() {
    $('.minicart-quantity').addClass('empty-cart');
    $('.minicart .icon-cart').addClass('empty-cart-trigger');
    $('.price-header').addClass('empty-cart');
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('change', '.quantity-select', function () {
        var parsedQuantity = parseInt($(this).val());
        if (parsedQuantity <= 1) {
            $(this).val(1);
            $(this).siblings('.decrease-quantity').addClass('icon--disabled');
        } else {
            $(this).val(parsedQuantity);
            if (parsedQuantity > 1) {
                $(this).siblings('.icon--disabled').removeClass('icon--disabled');
            }
        }
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        $('body > .modal-backdrop').remove();

        // doesn't allow removal of another product until first removal isn't finished
        $('i.remove-product').css('pointer-events', 'none');

        var pid = $(this).data('pid');
        var uuid = $(this).data('uuid') ? $(this).data('uuid') : $(`i[data-pid="${pid}"]`)[0].dataset.uuid;

        var url = $(this).data('action');
        var urlParams = {
            pid: pid,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
        })
            .done(function (data) {
                // doesn't allow removal of another product until first removal isn't finished
                $('i.remove-product').css('pointer-events', 'none');

                if (data.basketModel.items.length === 0) {
                    hideCartItemsHeader();
                }
                $('body').trigger('cart:update', ['delete_item', data.basketModel]);

                var productQuantityInput = $(this).parents('.product-tile').find('.quantity-select');
                var uuid = productQuantityInput.data('uuid');
                var productCard = $(this).parents('.uuid-' + uuid + '');

                if (window.GTM_ENABLED && window.GTM_CONTAINER_ID) {
                    document.dispatchEvent(
                        new CustomEvent('GTM-push-removeFromCart', {
                            detail: {
                                element: productCard,
                                updateQuantity: true
                            }
                        })
                    );
                }

                document.dispatchEvent(
                    new CustomEvent('Voyado-monitor-cart', {
                        detail: {
                            uuid: data.basketModel.basketUUID,
                            items: data.basketModel.items,
                            locale: data.locale
                        }
                    })
                );

                if ($(`.add-to-cart[data-pid="${pid}"]`).hasClass('hide')) {
                    $(`.remove-product[data-pid="${pid}"]`).addClass('hide');
                    $(`.add-to-cart[data-pid="${pid}"]`).removeClass('hide');
                }

                // if donation isn't in the cart when the last product is removed
                if (data.basketModel.items.length == 0) {
                    $('[data-donations-wrapper]').addClass('hide');
                }

                // FM hide third checkout step when some product is removed from the cart
                if ($('.payments-container-widget').length) {
                    $('.payments-container-widget').hide();
                }
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            });
    });

    $('body').on('click', '.quantity-form > .minicart-update-quantity', function (e) {
        e.preventDefault();
        var preSelectQty = $('.quantity-field').data('pre-select-qty');

        $('.checkout .cart-widget').append('<div class="modal-backdrop show"><div class="loader-mask-icon"></div></div>');

        var url = $(this).data('action');
        var urlParams = {
            pid: $(this).data('pid'),
            quantity: $(this).data('qty'),
            uuid: $(this).data('uuid')
        };
        url = appendToUrl(url, urlParams);

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json'
        })
            .done(function (data) {
                $('body').trigger('cart:update', ['update_quantity', data]);

                // Update mini-cart counter also by executing the target event with expected payload
                const cartItemsCount = _.get(data, ['numItems']);
                const cartItemsCountTitle = _.get(data, ['resources', 'minicartCountOfItems']);
                if (cartItemsCount !== null && cartItemsCountTitle) {
                    $('.minicart').trigger('count:update', {
                        quantityTotal: cartItemsCount,
                        minicartCountOfItems: cartItemsCountTitle
                    });
                }

                var productQuantityInput = $(this).siblings('.quantity-select');
                var quantity = productQuantityInput.val();

                var uuid = productQuantityInput.data('uuid');
                var productCard = $(this).parents('.uuid-' + uuid + '');
                if (window.GTM_ENABLED && window.GTM_CONTAINER_ID) {
                    if ($(this).hasClass('minicart-decrease-quantity') && quantity > 1) {
                        document.dispatchEvent(
                            new CustomEvent('GTM-push-removeFromCart', {
                                detail: {
                                    element: productCard,
                                    updateQuantity: true
                                }
                            })
                        );
                    } else {
                        document.dispatchEvent(
                            new CustomEvent('GTM-push-addToCart', {
                                detail: {
                                    element: productCard
                                }
                            })
                        );
                    }
                }

                document.dispatchEvent(
                    new CustomEvent('Voyado-monitor-cart', {
                        detail: {
                            uuid: data.basketUUID,
                            items: data.items,
                            locale: data.locale
                        }
                    })
                );

                // FM hide third checkout step on quantity change
                if ($('.payments-container-widget').length) {
                    $('.payments-container-widget').hide();
                }
            })
            .fail(function (err) {
                $('.modal-backdrop').remove();
                if (err.responseJSON && err.responseJSON.errorMessage) {
                    window.MessagesMgr.error(err.responseJSON.errorMessage);
                }
                if (err.responseJSON && err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    // Do not append at the top error message which is shown now as a toastr message.
                    /*
                    createErrorNotification(err.responseJSON.errorMessage);
                    */
                    $(this).val(parseInt(preSelectQty, 10));
                }
            });
    });

    $('body').on('click', '.discount-code-header', function (e) {
        e.preventDefault();
        $('.coupon-error').addClass('visually-hidden');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('[data-discount-code-container]').addClass('active');
    });

    $('body').on('click', '.coupon-close-form', function (e) {
        e.preventDefault();
        $('[data-discount-code-container]').removeClass('active');
    });

    $('body').on('submit', '.promo-code-form', function (e) {
        e.preventDefault();

        $('.checkout .cart-widget').append('<div class="modal-backdrop show"><div class="loader-mask-icon"></div></div>');

        var couponCodeVal = $('.coupon-code-field').val();
        var couponCode = couponCodeVal.trim();
        $('.coupon-code-field').val(couponCode);
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.modal-backdrop').remove();
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-error').removeClass('visually-hidden');
            $('.coupon-removed-error-message').hide();
            $('.coupon-missing-error').show();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('.coupon-error').addClass('visually-hidden');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
        }).done(function (data) {
            if (data.error) {
                $('.modal-backdrop').remove();
                $('.coupon-error').removeClass('visually-hidden');
                $('.coupon-removed-error-message').hide();
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                $('.coupon-error-message').empty().append(data.errorMessage);
            } else {
                $('body').trigger('cart:update', ['add_coupon', data.basketModel]);
                // @TODO need to be figured out how to pass messages
                // if (data.rebalanceGiftCertificatePaymentsMsg !== '') {
                //     $('[data-cart-calculation-error]').css('display', 'block');
                //     $('.cart-calculation-error').empty().append(data.rebalanceGiftCertificatePaymentsMsg);
                // }
            }
            $('.modal-backdrop').remove();
            $('.coupon-code-field').val('');

            // FM hide third checkout step when coupon is applied
            if ($('.payments-container-widget').length) {
                $('.payments-container-widget').hide();
            }
        }).fail(function (err) {
            $('.modal-backdrop').remove();
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.errorMessage);
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function () {
        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        $('.checkout .cart-widget').append('<div class="modal-backdrop show"><div class="loader-mask-icon"></div></div>');

        var url = $(this).data('action');
        var urlParams = {
            code: $(this).data('code'),
            uuid: $(this).data('uuid')
        };
        url = appendToUrl(url, urlParams);
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json'
        }).done(function (data) {
            $('body').trigger('cart:update', ['delete_coupon', data]);

            // FM hide third checkout step when coupon is removed
            if ($('.payments-container-widget').length) {
                $('.payments-container-widget').hide();
            }
        }).fail(function (err) {
            $('.modal-backdrop').remove();
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
            }
        });
    });

    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.chooseBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
    // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order') || !$('.global-availability', dialog).data('available')
        );

        showCartBox(response);
    });

    $('body').on('product:updateAvailability', function (e, response) {
    // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var url = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = {
            uuid: $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid'),
            pid: base.getPidValue($(this)),
            quantity: $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity')
        };

        if (url) {
            $.ajax({
                url: url,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('cart:update', ['update_product_clobal', data.cartModel]);
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                    }
                }
            });
        }
    });

    function updateStorefront(quickview, newPrice, newQuantity) {
        $(quickview + '.promotion-box-tile').removeClass('active');
        $(quickview + '.prices-and-availability .price-sales').text(newPrice);
        $(quickview + '.detail-quantity.quantity-select').val(newQuantity);
        if (newQuantity != 1) {
            $(quickview + '.quantity-and-add-to-cart .decrease-quantity').removeClass('icon--disabled');
        } else {
            $(quickview + '.quantity-and-add-to-cart .decrease-quantity').addClass('icon--disabled');
        }
    }

    $('body').on('click', '.promotion-box-tile', function () {
        var newPrice = $(this).find('h5').text();
        var newQuantity = $(this).find('h4 span').text();
        var quickviewOpen = $('.quickview-modal').hasClass('show');
        var quickview = '';

        if (quickviewOpen) {
            quickview = '.quickview-modal ';
            updateStorefront(quickview, newPrice, newQuantity);
        } else {
            updateStorefront(quickview, newPrice, newQuantity);
        }

        $(this).addClass('active');
    });

    base.selectAttribute();
    // Attempt to fix double load of images in the quick view popup.
    // That code is triggered twice:
    // 1st time - when mini cart js logic is loaded there
    // 2d time - on quickView load. Since the 2d one is loaded on all pages - the next line is commented.
    // @see SFCC-1729
    // base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
