'use strict';

var cart = require('./cart');

var updateMiniCart = true;

function closeMinicart() {
    $('.minicart .popover').removeClass('show');
    $('body').removeClass('overflow-hidden');
    $('.overlay-minicart').addClass('hide');
}

function clearSearchDropdown() {
    $('input.search-field').val('');
    $('.suggestions').hide();
    $('body').removeClass('overflow-hidden');
    $('.overlay-search').addClass('hide');
    $('.popular-search').addClass('hide');
    $('span.clear-search').addClass('hide');
}

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.empty-cart-trigger').on('click', function () {
        $('.empty-cart-box').show();
        setTimeout(function () {
            $('.empty-cart-box').hide();
        }, 3000);
    });

    $('.minicart').on('click', function () {
        clearSearchDropdown();

        var count = parseInt($('.minicart .minicart-quantity').text(), 10);
        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('body').addClass('overflow-hidden');
                $('.minicart .popover').addClass('show');
                $('.overlay-minicart').removeClass('hide');
                return;
            }

            $('.overlay-minicart').removeClass('hide');
            $('body').addClass('overflow-hidden');
            $('.minicart .popover').addClass('show');
        }
    });

    $('body').on('click', '.close-minicart', function () {
        closeMinicart();
    });
    $('body').on('click', '.overlay-minicart', function () {
        closeMinicart();
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function (event, data) {
        $('body').trigger('cart:update', ['update_quantity', data.cart]);
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
